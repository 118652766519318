<template>
  <div>
    <div v-if="data.length" class="row">
      <div v-for="img in data" :key="img.id" class="col-3 mb-3">
        <v-img
          :src="img.src"
          aspect-ratio="1"
          class="grey lighten-2 pointer"
          @click="img.src && img.src !== 'loading' ? openImage(img.id) : fetchImage(img.id)"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                v-if="img.src === 'loading'"
                indeterminate
                color="grey lighten-5"
              />
              <font-awesome-icon
                v-else
                icon="undo"
                class="red--text"
              />
            </v-row>
          </template>
        </v-img>
      </div>
      <LightBox
        :index="currentImage"
        style="z-index: 1000"
        :items="fetchedImages"
        :slideshow="false"
        @close="currentImage = null"
        srcThumb="src"
        thumbsPosition="bottom"
        useZoomBar
      />
    </div>
    <div v-else class="no-data text-center">{{ $t('system.no_images') }}</div>
  </div>
</template>

<script>
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

export default {
  name: 'Gallery',
  components: {
    LightBox: () => import('vue-cool-lightbox')
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    currentImage: null
  }),
  computed: {
    fetchedImages() {
      return this.data.filter(img => img.src)
    }
  },
  methods: {
    fetchImage(imageId) {
      this.$emit('repeatFetching', imageId)
    },
    openImage(imageId) {
      this.currentImage = this.fetchedImages.findIndex(item => item.id === imageId)
    }
  }
}
</script>

<style scoped>

</style>
